import { useAuth } from '@clerk/clerk-react';
import { useCallback } from 'react';

interface FetchOptions extends RequestInit {
  headers?: Record<string, string>;
}

export const useApi = () => {
  const { getToken, isLoaded, isSignedIn } = useAuth();

  const fetchWithAuth = useCallback(async (endpoint: string, options: FetchOptions = {}) => {
    if (!isLoaded || !isSignedIn) {
      throw new Error('Not authenticated');
    }

    try {
      const token = await getToken();
      
      if (!token) {
        throw new Error('No authentication token available');
      }

      // Use HTTPS in development, standard URL in production
      const baseUrl = import.meta.env.DEV 
        ? 'https://localhost:3001'
        : 'https://productsense-ai-backend.onrender.com';

      const currentUrl = new URL(window.location.href);
      
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Origin': window.location.origin,
        'X-Forwarded-Host': currentUrl.host,
        'X-Forwarded-Proto': currentUrl.protocol.replace(':', ''),
        'User-Agent': navigator.userAgent,
        'Referer': window.location.href,
        ...options.headers,
      };

      const requestUrl = `${baseUrl}${endpoint}`;

      // Detailed request logging
      console.log('API Request:', {
        url: requestUrl,
        method: options.method || 'GET',
        headers: {
          ...headers,
          'Authorization': 'Bearer [REDACTED]'
        },
        body: options.body
      });

      const fetchOptions = {
        ...options,
        headers,
        credentials: 'include' as const,
        mode: 'cors' as const,
      };

      // Log full request details
      console.log('Full fetch options:', {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          'Authorization': 'Bearer [REDACTED]'
        }
      });

      const response = await fetch(requestUrl, fetchOptions);

      // Log response headers
      console.log('Response headers:', {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries())
      });

      // Handle non-JSON responses
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        console.error('Non-JSON response:', {
          status: response.status,
          contentType,
          body: text.substring(0, 500) // Log first 500 chars
        });
        throw new Error('Invalid response format');
      }

      const responseData = await response.json();

      // Log response data
      console.log('API Response:', {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries()),
        data: responseData
      });

      if (!response.ok) {
        throw new Error(responseData.error || `HTTP error! status: ${response.status}`);
      }

      return responseData;
    } catch (error) {
      // Enhanced error logging
      console.error('API request failed:', {
        endpoint,
        error: error instanceof Error ? {
          message: error.message,
          name: error.name,
          stack: error.stack
        } : 'Unknown error',
        type: error instanceof TypeError ? 'TypeError' : error instanceof Error ? 'Error' : 'Unknown'
      });
      throw error;
    }
  }, [getToken, isLoaded, isSignedIn]);

  return { fetchWithAuth };
};