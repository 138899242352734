import React from 'react';

export default function DataProcessingAgreement() {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-6">Data Processing Agreement</h1>
      <div className="prose max-w-none">
        <p className="mb-8"><strong>Effective Date:</strong> October 1, 2024</p>

        <div className="space-y-8">
          <section>
            <h2 className="text-xl font-semibold mb-4">1. Definitions</h2>
            <p className="mb-2">For the purposes of this Agreement:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Controller:</strong> The entity that determines the purposes and means of processing personal data.</li>
              <li><strong>Processor:</strong> Product Sense, which processes personal data on behalf of the Controller.</li>
              <li><strong>Data Subject:</strong> An individual whose personal data is processed.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">2. Processing Details</h2>
            <p className="mb-2">Product Sense will process personal data only as instructed by the Controller for the following purposes:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Providing the platform's functionalities.</li>
              <li>Integrating with third-party tools as requested by the Controller.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">3. Processor Obligations</h2>
            <p className="mb-2">Product Sense agrees to:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Process personal data only as per documented instructions from the Controller.</li>
              <li>Maintain confidentiality and ensure its employees and subprocessors comply with data protection obligations.</li>
              <li>Implement technical and organizational measures to ensure data security, including encryption and pseudonymization.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">4. Use of Subprocessors</h2>
            <p>Product Sense uses subprocessors listed in its <a href="/legal/subprocessors" className="text-indigo-600 hover:text-indigo-800">Subprocessor List</a>. The Controller 
            will be informed of any intended changes to subprocessors and has the right to object.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">5. International Data Transfers</h2>
            <p>If personal data is transferred outside the EU/EEA, Product Sense ensures compliance through Standard Contractual 
            Clauses (SCCs) or other approved mechanisms.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">6. Data Subject Rights</h2>
            <p>Product Sense will assist the Controller in fulfilling data subject requests, such as access, rectification, deletion, 
            and portability, to the extent applicable under GDPR.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">7. Security Measures</h2>
            <p className="mb-2">Product Sense implements industry-standard security measures, including:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Encryption for data in transit and at rest.</li>
              <li>Access controls and regular security training for employees.</li>
              <li>Incident response procedures to handle data breaches.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">8. Breach Notification</h2>
            <p>Product Sense will notify the Controller within 72 hours of becoming aware of any data breach involving personal data, 
            including details of the breach and steps taken to mitigate it.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">9. Termination</h2>
            <p>Upon termination of the agreement, all personal data will be returned or securely deleted unless otherwise required 
            by law.</p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-4">10. Standard Contractual Clauses (SCCs)</h2>
            <p className="mb-2">In relation to transfers of Customer Personal Data governed by the EU GDPR, the following SCCs apply:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Clause 7: The parties do not permit docking.</li>
              <li>Clause 9, Module 2(a): The parties select Option 2. The time period is 5 days.</li>
              <li>Clause 9, Module 3(a): The parties select Option 2. The time period is 5 days.</li>
              <li>Clause 11(a): The parties do not select the independent dispute resolution option.</li>
              <li>Clause 17: The parties agree that the governing jurisdiction is Irish law.</li>
              <li>Clause 18: For Modules 1-3, the parties agree that the forum is before the courts of Ireland.</li>
              <li>Annex I(A): The data exporter is Company. The data importer is Customer.io. Contact details for the parties are part of the Agreement.</li>
              <li>Annex I(B): The parties agree that Schedule 1 describes the transfer.</li>
              <li>Annex I(C): The competent supervisory authority is the supervisory authority that has primary jurisdiction over the data exporter.</li>
              <li>Annex II: The parties agree that Schedule 2 describes the technical and organizational measures applicable to the transfer.</li>
            </ul>

            <h3 className="text-lg font-semibold mt-6 mb-4">Localizing the Standard Contractual Clauses</h3>
            
            <div className="space-y-6">
              <div>
                <h4 className="font-medium mb-2">For Switzerland</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>The parties adopt the GDPR standard for all data transfers.</li>
                  <li>Any references in the EU SCCs to "Directive 95/46/EC" or "Regulation (EU) 2016/679" will be interpreted as references to the Swiss FADP, and references to specific Articles of "Regulation (EU) 2016/679" will be replaced with the equivalent article or section of the Swiss FADP;</li>
                  <li>References to "EU", "Union", "Member State" and "Member State law" will be interpreted as references to Switzerland and Swiss law, as the case may be, and will not be interpreted in such a way as to exclude data subjects in Switzerland from exercising their rights in their place of habitual residence in accordance with Clause 18(c) of the EU SCCs;</li>
                  <li>Clause 13 and Annex I(C): The competent authorities under Clause 13, and in Annex I(C), are the Federal Data Protection and Information Commissioner and, concurrently, the EEA member state authority identified above.</li>
                  <li>Clause 17: The parties agree that the governing jurisdiction is the laws of Switzerland.</li>
                  <li>Clause 18: For Modules 1-3, the parties agree that the forum is Switzerland. The parties agree to interpret the Standard Contractual Clauses so that Data Subjects in Switzerland are able to sue for their rights in Switzerland in accordance with Clause 18(c).</li>
                  <li>The parties agree to interpret the Standard Contractual Clauses so that "Data Subjects" includes information about Swiss legal entities until the revised Federal Act on Data Protection becomes operative.</li>
                </ul>
              </div>

              <div>
                <h4 className="font-medium mb-2">For the United Kingdom</h4>
                <ul className="list-disc pl-6 space-y-2">
                  <li>The parties agree that the Standard Contractual Clauses are deemed amended to the extent necessary that they operate for transfers from the United Kingdom to a Third Country and provide appropriate safeguards for transfers according to Article 46 of the United Kingdom General Data Protection Regulation ("UK GDPR"). Such amendments include changing references to the GDPR to the UK GDPR and changing references to EU Member States to the United Kingdom. Tables 1 to 3 in Part 1 of the UK Addendum is deemed completed respectively with the information set out in Section 7.4 and this Schedule 1. Table 4 in Part 1 is deemed completed by selecting "neither party." Any conflict between the terms of the EU SCCs and the UK Addendum will be resolved in accordance with Section 10 and Section 11 of the UK Addendum.</li>
                  <li>Clause 17: The parties agree that the governing jurisdiction is the United Kingdom.</li>
                  <li>Clause 18: For Modules 1-3, the parties agree that the forum is the courts of England and Wales. The parties agree that Data Subjects may bring legal proceedings against either party in the courts of any country in the United Kingdom.</li>
                  <li>It is not the intention of either party to contradict or restrict any of the provisions set forth in the Standard Contractual Clauses and, accordingly, if and to the extent the Standard Contractual Clauses conflict with any provision of the Agreement (including this DPA), the Standard Contractual Clauses prevail to the extent of such conflict.</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}