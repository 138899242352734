import React, { useEffect, useState } from 'react';
import { Video, MessageSquare, Slack, AlertCircle, Loader2 } from 'lucide-react';
import { useSlackService } from '../services/slack';
import { SlackChannels } from '../components/SlackChannels';
import { useAuth } from '@clerk/clerk-react';
import { useApi } from '../services/api';

interface Integration {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  connected: boolean;
  onConnect: () => void;
  onDisconnect?: () => void;
}

export default function Settings() {
  const { isLoaded, isSignedIn } = useAuth();
  const { fetchWithAuth } = useApi();
  const [integrationStates, setIntegrationStates] = useState({
    slack: false,
    zendesk: true,
    zoom: false
  });
  const [dbStatus, setDbStatus] = useState<string>('unknown');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  
  const { initiateSlackAuth, checkSlackConnection } = useSlackService();

  // Test database connection on load
  useEffect(() => {
    if (!isLoaded || !isSignedIn) return;

    let mounted = true;

    const testDatabase = async () => {
      try {
        console.log('Testing database connection...');
        const data = await fetchWithAuth('/api/feedback');
        console.log('Database test response:', data);
        
        if (mounted) {
          if (data.success) {
            setDbStatus('connected');
          } else {
            setDbStatus('error');
            setError('Database test failed');
          }
        }
      } catch (err) {
        console.error('Database test error:', err);
        if (mounted) {
          setDbStatus('error');
          setError(err instanceof Error ? err.message : 'Failed to connect to database');
        }
      }
    };

    testDatabase();

    return () => {
      mounted = false;
    };
  }, [isLoaded, isSignedIn, fetchWithAuth]);

  // Check initial connection status
  useEffect(() => {
    if (!isLoaded || !isSignedIn) return;

    let mounted = true;

    const checkConnections = async () => {
      if (!mounted) return;

      try {
        setError(null);
        const connected = await checkSlackConnection();
        
        if (mounted) {
          setIntegrationStates(prev => ({ ...prev, slack: connected }));
          setLoading(false);
        }
      } catch (err) {
        console.error('Connection check error:', err);
        if (mounted) {
          setError('Failed to check integration status. Please try again later.');
          setLoading(false);
        }
      }
    };

    checkConnections();

    return () => {
      mounted = false;
    };
  }, [isLoaded, isSignedIn, checkSlackConnection]);

  const handleSlackConnect = async () => {
    if (isConnecting || integrationStates.slack) return;

    try {
      setError(null);
      setIsConnecting(true);
      
      const result = await initiateSlackAuth();
      
      if (result.success) {
        setIntegrationStates(prev => ({ ...prev, slack: true }));
      }
    } catch (err) {
      console.error('Slack auth error:', err);
      setError(err instanceof Error ? err.message : 'Failed to connect to Slack');
    } finally {
      setIsConnecting(false);
    }
  };

  const handleSlackDisconnect = async () => {
    if (!integrationStates.slack || isDisconnecting) return;

    try {
      setError(null);
      setIsDisconnecting(true);

      const data = await fetchWithAuth('/api/slack/disconnect', {
        method: 'POST'
      });

      if (data.success) {
        setIntegrationStates(prev => ({ ...prev, slack: false }));
      } else {
        throw new Error(data.error || 'Failed to disconnect from Slack');
      }
    } catch (err) {
      console.error('Slack disconnect error:', err);
      setError(err instanceof Error ? err.message : 'Failed to disconnect from Slack');
    } finally {
      setIsDisconnecting(false);
    }
  };

  const integrations: Integration[] = [
    {
      id: 'zoom',
      name: 'Zoom',
      description: 'Analyze customer calls and meetings automatically',
      icon: <Video className="w-8 h-8 text-blue-500" />,
      connected: integrationStates.zoom,
      onConnect: () => {}
    },
    {
      id: 'zendesk',
      name: 'Zendesk',
      description: 'Import and analyze support ticket conversations',
      icon: <MessageSquare className="w-8 h-8 text-green-500" />,
      connected: integrationStates.zendesk,
      onConnect: () => {}
    },
    {
      id: 'slack',
      name: 'Slack',
      description: 'Monitor customer feedback channels in real-time',
      icon: <Slack className="w-8 h-8 text-purple-500" />,
      connected: integrationStates.slack,
      onConnect: handleSlackConnect,
      onDisconnect: handleSlackDisconnect
    }
  ];

  if (!isLoaded || !isSignedIn) {
    return (
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-center min-h-[400px]">
          <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
        </div>
      </main>
    );
  }

  return (
    <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Settings</h2>
        <p className="mt-1 text-gray-500">Manage your integrations and preferences</p>
        <p className="mt-1 text-sm text-gray-500">Database Status: {dbStatus}</p>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-center gap-3 text-red-700">
          <AlertCircle className="w-5 h-5 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      <div className="bg-white rounded-xl shadow-sm">
        <div className="px-6 py-5 border-b">
          <h3 className="text-lg font-medium text-gray-900">Integrations</h3>
        </div>
        {loading ? (
          <div className="px-6 py-8 text-center text-gray-500">
            <Loader2 className="w-6 h-6 animate-spin mx-auto mb-2" />
            Loading integrations...
          </div>
        ) : (
          <div className="divide-y">
            {integrations.map((integration) => (
              <div key={integration.id} className="px-6 py-5">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-4">
                    <div className="flex-shrink-0">
                      {integration.icon}
                    </div>
                    <div>
                      <h4 className="text-base font-medium text-gray-900">{integration.name}</h4>
                      <p className="text-sm text-gray-500">{integration.description}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    {integration.connected && integration.onDisconnect && (
                      <button
                        onClick={integration.onDisconnect}
                        disabled={isDisconnecting}
                        className="btn bg-red-50 text-red-700 hover:bg-red-100 disabled:opacity-50"
                      >
                        {isDisconnecting ? (
                          <span className="flex items-center">
                            <Loader2 className="w-4 h-4 animate-spin mr-2" />
                            Disconnecting...
                          </span>
                        ) : (
                          'Disconnect'
                        )}
                      </button>
                    )}
                    <button
                      onClick={integration.onConnect}
                      className={`btn ${
                        integration.connected
                          ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          : 'btn-primary'
                      } ${isConnecting && integration.id === 'slack' ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={loading || (isConnecting && integration.id === 'slack')}
                    >
                      {isConnecting && integration.id === 'slack' ? (
                        <span className="flex items-center">
                          <Loader2 className="w-4 h-4 animate-spin mr-2" />
                          Connecting...
                        </span>
                      ) : (
                        integration.connected ? 'Connected' : 'Connect'
                      )}
                    </button>
                  </div>
                </div>
                
                {integration.id === 'slack' && integration.connected && (
                  <div className="mt-4 border-t pt-4">
                    <h5 className="text-sm font-medium text-gray-900 mb-4">
                      Select channels to monitor
                    </h5>
                    <SlackChannels />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </main>
  );
}