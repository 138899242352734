import React from 'react';

export default function TermsOfService() {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-6">Terms of Service</h1>
      <div className="prose max-w-none">
        <p className="mb-8"><strong>Effective Date:</strong> October 1, 2024</p>
        
        <div className="space-y-8">
          <section>
            <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
            <p className="mb-4">Welcome to Product Sense. These Terms of Service ("Terms") govern your access to and use of our platform, 
            products, and services ("Services"). By using our Services, you agree to comply with these Terms.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">2. User Accounts</h2>
            <div className="pl-4 space-y-4">
              <div>
                <h3 className="font-medium mb-2">2.1 Account Creation:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Users must provide accurate and up-to-date information during account registration.</li>
                  <li>Accounts are personal to the user and should not be shared with others.</li>
                </ul>
              </div>
              
              <div>
                <h3 className="font-medium mb-2">2.2 User Responsibilities:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Users are responsible for ensuring the confidentiality of their login credentials.</li>
                  <li>All actions performed under the user's account are the responsibility of the user.</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">3. Acceptable Use</h2>
            <p className="mb-2">Users agree not to engage in any activities that:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Violate applicable laws or regulations.</li>
              <li>Compromise the security or integrity of the Services.</li>
              <li>Infringe upon the intellectual property rights of others.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">4. Intellectual Property</h2>
            <p>All content, trademarks, and other intellectual property provided through the Services are owned by Product Sense 
            or its licensors. Users are granted a limited license to use the Services as intended under these Terms.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">5. Service Modifications and Availability</h2>
            <p>We reserve the right to modify or discontinue the Services, temporarily or permanently, at any time with or without 
            notice. We are not liable for any disruptions or changes to the Services.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">6. Limitation of Liability</h2>
            <p>To the maximum extent permitted by law, Product Sense is not liable for any indirect, incidental, or consequential 
            damages arising from the use or inability to use the Services.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">7. Termination</h2>
            <p>We may suspend or terminate your access to the Services if you violate these Terms. Upon termination, your right to 
            use the Services will cease immediately.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">8. Governing Law</h2>
            <p>These Terms are governed by and construed in accordance with the laws of the State of Delaware and the United States of America. Any disputes will be 
            resolved in the courts of the State of Delaware and the United States of America.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">9. Contact Information</h2>
            <p>For any questions about these Terms, contact us at:</p>
            <p className="mt-2"><strong>Email:</strong> <a href="mailto:legal@product-sense.io" className="text-indigo-600 hover:text-indigo-800">legal@product-sense.io</a></p>
          </section>
        </div>
      </div>
    </div>
  );
}