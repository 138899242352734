import React, { useState } from 'react';
import { ArrowUpDown, ExternalLink } from 'lucide-react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import type { FeedbackTableItem } from '../types';

interface FeedbackTableProps {
  data: FeedbackTableItem[];
}

// Helper function to parse and render Slack-style markdown links and newlines
const renderContent = (content: string) => {
  // First split by newlines to handle them separately
  const lines = content.split(/\n/);
  
  return lines.map((line, lineIndex) => {
    // Match Slack-style links: <url|text> or just <url>
    const parts = line.split(/(<(?:(?:https?:\/\/)[^|>]+?\|[^>]+?>|(?:https?:\/\/)[^>]+?>))/g);
    
    const renderedLine = parts.map((part, partIndex) => {
      // Check if this part is a link
      if (part.startsWith('<') && part.endsWith('>')) {
        // Remove < and >
        const linkContent = part.slice(1, -1);
        // Split into URL and text if separator exists
        const [url, text] = linkContent.split('|');
        
        return (
          <a
            key={`${lineIndex}-${partIndex}`}
            href={url.startsWith('http') ? url : `https://${url}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 hover:underline"
            onClick={(e) => e.stopPropagation()}
          >
            {text || url}
          </a>
        );
      }
      
      // Return regular text
      return part;
    });

    // Return the line with a break if it's not the last line
    return (
      <React.Fragment key={lineIndex}>
        {renderedLine}
        {lineIndex < lines.length - 1 && <br />}
      </React.Fragment>
    );
  });
};

// Helper function to truncate text while preserving words
const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  
  // Find the last space within the limit
  const truncated = text.slice(0, maxLength);
  const lastSpace = truncated.lastIndexOf(' ');
  
  // If we found a space, truncate at the space, otherwise use the full length
  return (lastSpace > 0 ? truncated.slice(0, lastSpace) : truncated) + '...';
};

export const FeedbackTable: React.FC<FeedbackTableProps> = ({ data }) => {
  const [sortField, setSortField] = useState<keyof FeedbackTableItem>('timestamp');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const handleSort = (field: keyof FeedbackTableItem) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortField === 'timestamp') {
      return sortDirection === 'asc' 
        ? new Date(a[sortField]).getTime() - new Date(b[sortField]).getTime()
        : new Date(b[sortField]).getTime() - new Date(a[sortField]).getTime();
    }
    return sortDirection === 'asc'
      ? a[sortField].toString().localeCompare(b[sortField].toString())
      : b[sortField].toString().localeCompare(a[sortField].toString());
  });

  const getSentimentEmoji = (sentiment: string) => {
    switch (sentiment) {
      case 'positive':
        return '😊';
      case 'negative':
        return '😞';
      default:
        return '😐';
    }
  };

  const getThemeClass = (theme: string) => {
    switch (theme.toLowerCase()) {
      case 'usability':
        return 'bg-yellow-100 text-yellow-800';
      case 'value':
        return 'bg-pink-100 text-pink-800';
      case 'reliability':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getSourceContent = (source: string) => {
    switch (source.toLowerCase()) {
      case 'zendesk':
        return (
          <>
            <img 
              src="/icons/zendesk.png" 
              alt="Zendesk" 
              className="w-5 h-5 object-contain flex-shrink-0"
            />
            <span className="text-gray-900">Zendesk</span>
          </>
        );
      case 'slack':
        return (
          <>
            <img 
              src="/icons/slack.png" 
              alt="Slack" 
              className="w-5 h-5 object-contain flex-shrink-0"
            />
            <span className="text-gray-900">Slack</span>
          </>
        );
      case 'call':
        return (
          <>
            <span className="text-xl">📞</span>
            <span className="text-gray-900">Call</span>
          </>
        );
      default:
        return (
          <>
            <span className="text-xl">📝</span>
            <span className="text-gray-900">{source}</span>
          </>
        );
    }
  };

  const renderTooltipContent = (item: FeedbackTableItem) => (
    <div className="space-y-4">
      <div className="whitespace-pre-wrap break-words leading-relaxed">
        {renderContent(item.content)}
      </div>
      {item.source.toLowerCase() === 'slack' && item.slackUrl && (
        <div className="pt-4 border-t border-gray-200">
          <a
            href={item.slackUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
            onClick={(e) => e.stopPropagation()}
          >
            <img 
              src="/icons/slack.png" 
              alt="View in Slack" 
              className="w-4 h-4 object-contain"
            />
            <span className="text-sm">View in Slack</span>
            <ExternalLink className="w-3 h-3" />
          </a>
        </div>
      )}
    </div>
  );

  return (
    <div className="overflow-hidden">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">Feedback</h2>
        <button className="text-blue-600 hover:text-blue-800 text-sm font-medium">
          Export
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th 
                className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                onClick={() => handleSort('content')}
              >
                <div className="flex items-center gap-2">
                  Feedback
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th 
                className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                onClick={() => handleSort('persona')}
              >
                <div className="flex items-center gap-2">
                  Persona
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th 
                className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                onClick={() => handleSort('theme')}
              >
                <div className="flex items-center gap-2">
                  Theme
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
              <th 
                className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                onClick={() => handleSort('source')}
              >
                <div className="flex items-center gap-2">
                  Source
                  <ArrowUpDown className="w-4 h-4" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedData.map((item) => (
              <tr key={item.id} className="hover:bg-gray-50">
                <td className="px-4 py-4 max-w-md">
                  <div className="flex items-start gap-2">
                    <span className="text-xl flex-shrink-0">{getSentimentEmoji(item.sentiment)}</span>
                    <Tippy 
                      content={renderTooltipContent(item)}
                      theme="light"
                      placement="top"
                      duration={200}
                      interactive={true}
                      maxWidth={600}
                      className="!bg-white !text-gray-900 !shadow-lg !rounded-lg !p-4 !border !border-gray-200"
                    >
                      <span className="text-gray-900 break-words cursor-help">
                        {renderContent(truncateText(item.content, 100))}
                      </span>
                    </Tippy>
                  </div>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    <img 
                      src={`https://api.dicebear.com/7.x/avataaars/svg?seed=${item.persona}`} 
                      alt={item.persona}
                      className="w-6 h-6 rounded-full flex-shrink-0"
                    />
                    <span className="text-gray-900">{item.persona}</span>
                  </div>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${getThemeClass(item.theme)}`}>
                    {item.theme}
                  </span>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    {getSourceContent(item.source)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};