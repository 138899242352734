import { useCallback } from 'react';
import { useApi } from './api';

interface SlackAuthResponse {
  success: boolean;
  team?: string;
  user?: string;
}

export const useSlackService = () => {
  const { fetchWithAuth } = useApi();

  const initiateSlackAuth = async (): Promise<SlackAuthResponse> => {
    const width = 600;
    const height = 800;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    const state = Math.random().toString(36).substring(7);
    sessionStorage.setItem('slackOAuthState', state);
    
    const redirectUri = `${window.location.protocol}//${window.location.host}/slack/callback.html`;
    console.log('Redirect URI:', redirectUri);

    return new Promise((resolve, reject) => {
      let messageReceived = false;
      
      const handleCallback = async (event: MessageEvent) => {
        console.log('Received postMessage event:', event.origin, event.data);
        
        // Ignore messages that don't have our expected structure
        if (!event.data || typeof event.data !== 'object' || !('slackOAuth' in event.data)) {
          console.log('Ignoring non-Slack message');
          return;
        }

        try {
          console.log('Processing Slack callback data:', event.data);
          const { code, state: returnedState } = event.data.slackOAuth;
          
          if (!code || !returnedState) {
            console.log('Missing code or state in response');
            return;
          }

          if (returnedState !== state) {
            throw new Error('Invalid state parameter');
          }

          messageReceived = true;

          const response = await fetchWithAuth('/api/slack/oauth', {
            method: 'POST',
            body: JSON.stringify({ 
              code,
              redirect_uri: redirectUri
            }),
          });

          resolve(response);
        } catch (error) {
          console.error('Error in OAuth callback:', error);
          reject(error);
        } finally {
          window.removeEventListener('message', handleCallback);
        }
      };

      window.addEventListener('message', handleCallback);

      const authUrl = new URL('https://slack.com/oauth/v2/authorize');
      authUrl.searchParams.append('client_id', '7454949507506.7864240382710');
      authUrl.searchParams.append('user_scope', 'channels:read,groups:read,im:read,mpim:read');
      authUrl.searchParams.append('redirect_uri', redirectUri);
      authUrl.searchParams.append('state', state);
      
      console.log('Opening Slack auth URL:', authUrl.toString());
      
      const popup = window.open(
        authUrl.toString(),
        'SlackAuth',
        `width=${width},height=${height},left=${left},top=${top}`
      );

      if (!popup) {
        window.removeEventListener('message', handleCallback);
        reject(new Error('Failed to open popup. Please allow popups for this site.'));
        return;
      }

      const pollTimer = setInterval(() => {
        if (!popup || popup.closed) {
          clearInterval(pollTimer);
          window.removeEventListener('message', handleCallback);
          if (!messageReceived) {
            reject(new Error('Authentication cancelled or popup closed'));
          }
        }
      }, 500);

      // Clear poll timer after 5 minutes (timeout)
      setTimeout(() => {
        clearInterval(pollTimer);
        window.removeEventListener('message', handleCallback);
        if (!messageReceived) {
          reject(new Error('Authentication timed out'));
        }
      }, 300000); // 5 minutes
    });
  };

  const checkSlackConnection = useCallback(async (): Promise<boolean> => {
    try {
      const data = await fetchWithAuth('/api/slack/status');
      return data.connected;
    } catch (error) {
      console.error('Error checking connection:', error);
      throw error;
    }
  }, [fetchWithAuth]);

  return {
    initiateSlackAuth,
    checkSlackConnection
  };
};