import React from 'react';

export default function SubprocessorList() {
  const subprocessors = [
    {
      name: 'Render',
      purpose: 'Cloud Hosting',
      location: 'USA',
      link: 'https://trust.render.com'
    },
    {
      name: 'Mixpanel',
      purpose: 'Product Analytics',
      location: 'USA',
      link: 'https://mixpanel.com'
    },
    {
      name: 'Customer.io',
      purpose: 'Customer Communications',
      location: 'USA',
      link: 'https://customer.io'
    },
    {
      name: 'Freshworks',
      purpose: 'Customer Support',
      location: 'USA',
      link: 'https://www.freshworks.com'
    },
    {
      name: 'Slack',
      purpose: 'Internal Communications',
      location: 'USA',
      link: 'https://slack.com'
    },
    {
      name: 'Notion',
      purpose: 'Internal Communications',
      location: 'USA',
      link: 'https://notion.so'
    }
  ];

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-6">Subprocessor List</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Sub-processor
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Purpose
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                More Information
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {subprocessors.map((processor) => (
              <tr key={processor.name}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {processor.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {processor.purpose}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {processor.location}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <a 
                    href={processor.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    {processor.link}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}