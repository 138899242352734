import React from 'react';
import { Circle } from 'lucide-react';
import type { ActivityItem } from '../types';

interface ActivityFeedProps {
  activities: ActivityItem[];
}

export const ActivityFeed: React.FC<ActivityFeedProps> = ({ activities }) => {
  const getActivityIcon = (type: string) => {
    switch (type) {
      case 'new_feedback':
        return <Circle className="w-4 h-4 text-purple-500" />;
      case 'score_change':
        return <Circle className="w-4 h-4 text-green-500" />;
      case 'service_disconnected':
        return <Circle className="w-4 h-4 text-red-500" />;
      default:
        return null;
    }
  };

  const getActivityText = (activity: ActivityItem) => {
    switch (activity.type) {
      case 'new_feedback':
        return 'New feedback received';
      case 'score_change':
        return `Change in ${activity.details?.category} score`;
      case 'service_disconnected':
        return 'Service disconnected';
      default:
        return '';
    }
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Activity</h2>
      <div className="space-y-4">
        {activities.map((activity) => (
          <div key={activity.id} className="flex items-start gap-3">
            {getActivityIcon(activity.type)}
            <div className="flex-1 min-w-0">
              <div className="flex items-baseline justify-between gap-2">
                <p className="text-gray-900">{getActivityText(activity)}</p>
                <span className="text-sm text-gray-500 whitespace-nowrap">
                  {new Date(activity.timestamp).toLocaleString()}
                </span>
              </div>
              <p className="text-sm text-gray-500">
                from <span className="font-medium">{activity.source}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};