import React from 'react';
import { Routes, Route, NavLink, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import DataProcessingAgreement from './DataProcessingAgreement';
import SubprocessorList from './SubprocessorList';
import { Footer } from '../../components/Footer';

export default function Legal() {
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();

  const handleLogoClick = () => {
    if (isSignedIn) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <div className="flex-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex gap-8">
            {/* Sidebar */}
            <div className="w-64 flex-shrink-0">
              <div className="bg-white rounded-lg shadow-sm p-4">
                <div className="mb-6 flex items-center gap-3 cursor-pointer" onClick={handleLogoClick}>
                  <img src="/logo.png" alt="Product Sense" className="h-8 w-auto" />
                  <span className="text-xl font-bold text-gray-900">Product Sense</span>
                </div>
                <nav className="space-y-1">
                  <NavLink
                    to="/legal/terms"
                    className={({ isActive }) =>
                      `block px-4 py-2 rounded-md text-sm font-medium ${
                        isActive
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:bg-gray-50'
                      }`
                    }
                  >
                    Terms of Service
                  </NavLink>
                  <NavLink
                    to="/legal/privacy"
                    className={({ isActive }) =>
                      `block px-4 py-2 rounded-md text-sm font-medium ${
                        isActive
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:bg-gray-50'
                      }`
                    }
                  >
                    Privacy Policy
                  </NavLink>
                  <NavLink
                    to="/legal/dpa"
                    className={({ isActive }) =>
                      `block px-4 py-2 rounded-md text-sm font-medium ${
                        isActive
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:bg-gray-50'
                      }`
                    }
                  >
                    Data Processing Agreement
                  </NavLink>
                  <NavLink
                    to="/legal/subprocessors"
                    className={({ isActive }) =>
                      `block px-4 py-2 rounded-md text-sm font-medium ${
                        isActive
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:bg-gray-50'
                      }`
                    }
                  >
                    Subprocessor List
                  </NavLink>
                </nav>
              </div>
            </div>

            {/* Content */}
            <div className="flex-1">
              <div className="bg-white rounded-lg shadow-sm">
                <Routes>
                  <Route path="/" element={<Navigate to="/legal/terms" replace />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/dpa" element={<DataProcessingAgreement />} />
                  <Route path="/subprocessors" element={<SubprocessorList />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}