import React, { useState, useEffect, useCallback } from 'react';
import { Check, Loader2, AlertCircle, Search } from 'lucide-react';
import { useApi } from '../services/api';
import { useAuth } from '@clerk/clerk-react';

interface Channel {
  id: string;
  name: string;
  type: 'public' | 'private';
  members: number;
  topic?: string;
  purpose?: string;
  synced?: boolean;
  syncStatus?: 'pending' | 'in_progress' | 'completed' | 'failed';
  syncProgress?: number;
  syncError?: string;
}

export const SlackChannels: React.FC = () => {
  const { fetchWithAuth } = useApi();
  const { userId } = useAuth();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<Set<string>>(new Set());
  const [syncedChannels, setSyncedChannels] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [progressCheckInterval, setProgressCheckInterval] = useState<NodeJS.Timeout | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const checkSyncProgress = useCallback(async () => {
    if (!userId) return;

    try {
      const data = await fetchWithAuth('/api/slack/channels/sync-status');
      if (!data.success) return;

      let hasInProgress = false;
      let allComplete = true;

      setChannels(prevChannels => {
        const updatedChannels = prevChannels.map(channel => {
          const status = data.channels.find(s => s.channel_id === channel.id);
          if (status) {
            const isInProgress = status.sync_status === 'in_progress';
            hasInProgress = hasInProgress || isInProgress;
            allComplete = allComplete && status.sync_status === 'completed';

            return {
              ...channel,
              syncStatus: status.sync_status,
              syncProgress: status.sync_progress,
              synced: true
            };
          }
          return channel;
        });
        return updatedChannels;
      });

      if ((!hasInProgress || allComplete) && progressCheckInterval) {
        clearInterval(progressCheckInterval);
        setProgressCheckInterval(null);
      }
    } catch (error) {
      console.error('Error checking sync progress:', error);
    }
  }, [fetchWithAuth, userId, progressCheckInterval]);

  // Set up progress checking
  useEffect(() => {
    const hasInProgressChannels = channels.some(
      channel => channel.syncStatus === 'in_progress'
    );

    if (hasInProgressChannels && !progressCheckInterval) {
      const interval = setInterval(checkSyncProgress, 500);
      setProgressCheckInterval(interval);
    }

    return () => {
      if (progressCheckInterval) {
        clearInterval(progressCheckInterval);
      }
    };
  }, [checkSyncProgress, channels]);

  // Initial fetch
  useEffect(() => {
    if (userId) {
      fetchChannels();
    }
  }, [userId]);

  // Auto-expand when no synced channels
  useEffect(() => {
    if (!loading && syncedChannels.size === 0) {
      setIsExpanded(true);
    }
  }, [loading, syncedChannels.size]);

  const fetchChannels = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const data = await fetchWithAuth('/api/slack/channels');
      if (!data.success) throw new Error(data.error);
      
      const sortedChannels = data.channels.sort((a: Channel, b: Channel) => 
        a.name.localeCompare(b.name)
      );

      const syncedData = await fetchWithAuth('/api/slack/channels/synced');
      if (syncedData.success) {
        const syncedSet = new Set(syncedData.channels.map((c: any) => c.channel_id));
        setSyncedChannels(syncedSet);
        setSelectedChannels(new Set(syncedSet));
        
        sortedChannels.forEach(channel => {
          const syncedChannel = syncedData.channels.find((c: any) => c.channel_id === channel.id);
          if (syncedChannel) {
            channel.synced = true;
            channel.syncStatus = syncedChannel.sync_status;
            channel.syncProgress = syncedChannel.sync_progress;
            channel.syncError = syncedChannel.sync_error;
          }
        });
      }

      setChannels(sortedChannels);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch channels');
    } finally {
      setLoading(false);
    }
  };

  const toggleChannel = (channelId: string) => {
    setSelectedChannels(prev => {
      const next = new Set(prev);
      if (next.has(channelId)) {
        next.delete(channelId);
      } else {
        next.add(channelId);
      }
      return next;
    });
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);

      const selectedChannelsList = channels.filter(channel => 
        selectedChannels.has(channel.id)
      );

      const data = await fetchWithAuth('/api/slack/channels', {
        method: 'POST',
        body: JSON.stringify({ channels: selectedChannelsList }),
      });

      if (!data.success) throw new Error(data.error);

      setSyncedChannels(selectedChannels);
      setIsExpanded(false);

      setChannels(prev => prev.map(channel => ({
        ...channel,
        syncStatus: selectedChannels.has(channel.id) ? 'in_progress' : channel.syncStatus,
        syncProgress: selectedChannels.has(channel.id) ? 0 : channel.syncProgress,
        synced: selectedChannels.has(channel.id) || channel.synced
      })));

    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save channels');
    } finally {
      setSaving(false);
    }
  };

  const handleChannelClick = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  const getSyncStatus = (channel: Channel) => {
    if (!channel.synced) return null;

    switch (channel.syncStatus) {
      case 'in_progress':
        return (
          <div className="flex items-center gap-2 min-w-[150px]">
            <div className="flex-1 h-2 bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full bg-yellow-400 transition-all duration-500"
                style={{ width: `${channel.syncProgress || 0}%` }}
              />
            </div>
            <span className="text-xs text-gray-600 whitespace-nowrap">
              {channel.syncProgress || 0}%
            </span>
          </div>
        );
      case 'completed':
        return (
          <span className="text-xs px-2 py-0.5 rounded-full bg-green-100 text-green-800">
            Synced
          </span>
        );
      case 'failed':
        return (
          <span className="text-xs px-2 py-0.5 rounded-full bg-red-100 text-red-800" title={channel.syncError}>
            Failed
          </span>
        );
      default:
        return (
          <span className="text-xs px-2 py-0.5 rounded-full bg-gray-100 text-gray-800">
            Pending
          </span>
        );
    }
  };

  const filteredChannels = channels.filter(channel =>
    channel.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="w-6 h-6 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 rounded-lg flex items-center gap-3 text-red-700">
        <AlertCircle className="w-5 h-5 flex-shrink-0" />
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Header with sync status */}
      <div>
        <h3 className="text-sm font-medium text-gray-900">Connected Channels</h3>
        <p className="text-sm text-gray-500">
          {Array.from(syncedChannels).length} channel{syncedChannels.size !== 1 ? 's' : ''} synced
        </p>
      </div>

      {/* Synced channels preview */}
      {!isExpanded && syncedChannels.size > 0 && (
        <div className="space-y-2">
          {channels
            .filter(channel => syncedChannels.has(channel.id))
            .map(channel => (
              <div
                key={channel.id}
                className="flex items-center justify-between p-3 bg-white rounded-lg border cursor-pointer hover:bg-gray-50"
                onClick={handleChannelClick}
              >
                <div className="flex items-center gap-2">
                  <span className="font-medium">#{channel.name}</span>
                  {getSyncStatus(channel)}
                </div>
                <span className="text-sm text-gray-500">
                  {channel.members} members
                </span>
              </div>
            ))}
          
          <button
            onClick={() => setIsExpanded(true)}
            className="w-full text-sm text-gray-600 hover:text-gray-900 py-2"
          >
            See all channels
          </button>
        </div>
      )}

      {/* Channel selection interface */}
      {isExpanded && (
        <>
          {/* Search bar */}
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search channels..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          {/* Channel list */}
          <div className="grid gap-2">
            {filteredChannels.map(channel => (
              <label
                key={channel.id}
                className="flex items-center p-3 bg-white rounded-lg border cursor-pointer hover:bg-gray-50"
              >
                <input
                  type="checkbox"
                  className="hidden"
                  checked={selectedChannels.has(channel.id)}
                  onChange={() => toggleChannel(channel.id)}
                />
                <div className={`
                  w-5 h-5 rounded border mr-3 flex items-center justify-center
                  ${selectedChannels.has(channel.id) 
                    ? 'bg-blue-600 border-blue-600' 
                    : 'border-gray-300'
                  }
                `}>
                  {selectedChannels.has(channel.id) && (
                    <Check className="w-3.5 h-3.5 text-white" />
                  )}
                </div>
                <div className="flex-1">
                  <div className="flex items-center gap-2">
                    <span className="font-medium">#{channel.name}</span>
                    <span className={`
                      text-xs px-2 py-0.5 rounded-full
                      ${channel.type === 'private' 
                        ? 'bg-yellow-100 text-yellow-800' 
                        : 'bg-green-100 text-green-800'
                      }
                    `}>
                      {channel.type}
                    </span>
                    {getSyncStatus(channel)}
                  </div>
                  <p className="text-sm text-gray-500">
                    {channel.members} members
                  </p>
                  {(channel.topic || channel.purpose) && (
                    <p className="text-sm text-gray-500 mt-1">
                      {channel.topic || channel.purpose}
                    </p>
                  )}
                </div>
              </label>
            ))}
          </div>

          {/* Action button */}
          <button
            onClick={handleSave}
            disabled={selectedChannels.size === 0 || saving}
            className={`
              w-full btn btn-primary flex items-center justify-center
              ${(selectedChannels.size === 0 || saving) ? 'opacity-50 cursor-not-allowed' : ''}
            `}
          >
            {saving ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin mr-2" />
                Saving...
              </>
            ) : (
              syncedChannels.size > 0 ? 'Update connections' : 'Connect to ProductSense'
            )}
          </button>
        </>
      )}
    </div>
  );
};