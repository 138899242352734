import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';

export const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();

  const handleLogoClick = () => {
    if (isSignedIn) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  return (
    <footer className="py-4 text-center text-sm text-gray-500 border-t">
      <span 
        className="cursor-pointer hover:text-gray-900"
        onClick={handleLogoClick}
      >
        © 2024 Product Sense
      </span>
      <span className="mx-2">•</span>
      <Link to="/support" className="text-gray-600 hover:text-gray-900">Support</Link>
      <span className="mx-2">•</span>
      <Link to="/legal" className="text-gray-600 hover:text-gray-900">Legal</Link>
    </footer>
  );
};