import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';
import { Search, Bell, Settings, LayoutDashboard } from 'lucide-react';
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";
import { Footer } from './Footer';

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path: string) => {
    if (path === '/dashboard') {
      return location.pathname === '/' || location.pathname === '/dashboard';
    }
    return location.pathname === path;
  };

  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-white border-b sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center gap-4">
              <Link to="/" className="flex items-center gap-3">
                <img src="/icons/logo.png" alt="Product Sense" className="h-8 w-auto" />
                <span className="text-xl font-bold text-gray-900">Product Sense</span>
              </Link>
              
              <SignedIn>
                <nav className="hidden md:flex items-center gap-6 ml-8">
                  <Link 
                    to="/dashboard" 
                    className={`text-sm font-medium ${
                      isActive('/dashboard')
                        ? 'text-blue-600' 
                        : 'text-gray-500 hover:text-gray-900'
                    }`}
                  >
                    Dashboard
                  </Link>
                  <Link 
                    to="/settings" 
                    className={`text-sm font-medium ${
                      isActive('/settings') 
                        ? 'text-blue-600' 
                        : 'text-gray-500 hover:text-gray-900'
                    }`}
                  >
                    Settings
                  </Link>
                </nav>
              </SignedIn>
            </div>

            <div className="flex items-center gap-4">
              <SignedIn>
                <button 
                  onClick={() => navigate('/search')} 
                  className="p-2 text-gray-400 hover:text-gray-500"
                >
                  <Search className="w-5 h-5" />
                </button>
                <button 
                  onClick={() => navigate('/notifications')} 
                  className="p-2 text-gray-400 hover:text-gray-500 relative"
                >
                  <Bell className="w-5 h-5" />
                  <span className="absolute top-1.5 right-1.5 w-2 h-2 bg-red-500 rounded-full"></span>
                </button>
                <button 
                  onClick={() => navigate('/settings')}
                  className="p-2 text-gray-400 hover:text-gray-500 md:hidden"
                >
                  <Settings className="w-5 h-5" />
                </button>
                <UserButton afterSignOutUrl="/" />
              </SignedIn>
              <SignedOut>
                <SignInButton mode="modal">
                  <button className="btn btn-primary">
                    Sign In
                  </button>
                </SignInButton>
              </SignedOut>
            </div>
          </div>
        </div>
      </header>

      <main className="flex-1 bg-gray-50">
        <SignedIn>
          <div className="flex flex-col min-h-full">
            <div className="flex-1">
              <Outlet />
            </div>
            <Footer />
          </div>
        </SignedIn>
        <SignedOut>
          <div className="flex flex-col min-h-[calc(100vh-4rem)]">
            <div className="flex-1 flex items-center justify-center">
              <div className="text-center">
                <div className="flex justify-center mb-6">
                  <img src="/icons/logo.png" alt="Product Sense" className="h-12 w-auto" />
                </div>
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                  Welcome to Product Sense
                </h1>
                <p className="text-gray-600 mb-8">
                  Please sign in to access your dashboard and settings.
                </p>
                <SignInButton mode="modal">
                  <button className="btn btn-primary">
                    Sign In to Continue
                  </button>
                </SignInButton>
              </div>
            </div>
            <Footer />
          </div>
        </SignedOut>
      </main>
    </div>
  );
}