import { useState, useEffect } from 'react';
import { ChevronDown, Loader2, AlertCircle } from 'lucide-react';
import { useApi } from '../services/api';
import { MetricCard } from '../components/MetricCard';
import { TrendChart } from '../components/TrendChart';
import { FeedbackTable } from '../components/FeedbackTable';
import { ActivityFeed } from '../components/ActivityFeed';
import type { MetricCard as MetricCardType, FeedbackTableItem, ActivityItem } from '../types';

const mockMetrics: MetricCardType[] = [
  { category: 'Security', score: 90, change: 12, color: 'bg-indigo-400' },
  { category: 'Reliability', score: 29, change: -25, color: 'bg-red-400' },
  { category: 'Performance', score: 66, change: -1, color: 'bg-yellow-400' },
  { category: 'Usability', score: 64, change: 12, color: 'bg-emerald-400' },
  { category: 'Value', score: 81, change: 0, color: 'bg-blue-400' }
];

export default function Dashboard() {
  const { fetchWithAuth } = useApi();
  const [timeRange] = useState('Last 7 days');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [feedback, setFeedback] = useState<FeedbackTableItem[]>([]);
  const [activities, setActivities] = useState<ActivityItem[]>([]);
  const [retryCount, setRetryCount] = useState(0);
  const [cursor, setCursor] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const maxRetries = 3;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        console.log('Fetching dashboard data...', { retryCount, cursor });

        const url = cursor 
          ? `/api/feedback?cursor=${cursor}&limit=50`
          : '/api/feedback?limit=50';

        const data = await fetchWithAuth(url);
        
        console.log('Dashboard data received:', {
          success: data.success,
          feedbackCount: data.feedback?.length,
          activitiesCount: data.activities?.length,
          pagination: data.pagination,
          sample: {
            feedback: data.feedback?.slice(0, 2).map(f => ({
              id: f.id,
              contentLength: f.content?.length,
              hasContent: !!f.content,
              sentiment: f.sentiment,
              theme: f.theme
            })),
            activities: data.activities?.slice(0, 2)
          }
        });
        
        if (!data.success) {
          throw new Error(data.error || 'Failed to fetch data');
        }

        // Validate feedback data
        const validFeedback = (data.feedback || []).filter(item => {
          const isValid = item && 
                         typeof item === 'object' && 
                         item.content && 
                         typeof item.content === 'string' &&
                         item.content.trim().length > 0;
          
          if (!isValid) {
            console.warn('Invalid feedback item:', {
              id: item?.id,
              hasContent: !!item?.content,
              contentType: typeof item?.content,
              contentLength: item?.content?.length
            });
          }
          
          return isValid;
        });

        if (validFeedback.length === 0 && data.feedback?.length > 0) {
          console.warn('No valid feedback items found:', {
            total: data.feedback.length,
            sample: data.feedback.slice(0, 2)
          });
        }

        setFeedback(prev => cursor ? [...prev, ...validFeedback] : validFeedback);
        setActivities(data.activities || []);
        setHasMore(data.pagination.hasMore);
        setCursor(data.pagination.nextCursor);
        setRetryCount(0); // Reset retry count on success
      } catch (err) {
        console.error('Error fetching dashboard data:', {
          error: err instanceof Error ? err.message : 'Unknown error',
          retryCount,
          maxRetries,
          cursor
        });

        if (retryCount < maxRetries) {
          setRetryCount(prev => prev + 1);
          setError(`Failed to load data, retrying... (${retryCount + 1}/${maxRetries})`);
          return; // Will trigger useEffect again due to retryCount change
        }

        setError(err instanceof Error ? err.message : 'Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchWithAuth, retryCount, cursor]);

  const loadMore = () => {
    if (!loading && hasMore) {
      setLoading(true);
    }
  };

  if (loading && !cursor) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-center min-h-[400px]">
          <div className="text-center">
            <Loader2 className="w-8 h-8 animate-spin mx-auto mb-4 text-blue-600" />
            <p className="text-gray-500">Loading dashboard data...</p>
            {retryCount > 0 && (
              <p className="text-sm text-gray-400 mt-2">
                Retry attempt {retryCount}/{maxRetries}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Dashboard Overview</h1>
          <p className="mt-1 text-sm text-gray-500">Track and analyze customer feedback across all channels</p>
        </div>
        <button className="flex items-center gap-2 px-4 py-2 bg-white border rounded-lg text-sm text-gray-600 hover:bg-gray-50 shadow-sm">
          {timeRange}
          <ChevronDown className="w-4 h-4" />
        </button>
      </div>

      {error && (
        <div className="mb-8 p-4 bg-red-50 border border-red-200 rounded-lg flex items-center gap-3">
          <AlertCircle className="w-5 h-5 text-red-500 flex-shrink-0" />
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-8">
        {mockMetrics.map((metric) => (
          <MetricCard key={metric.category} {...metric} />
        ))}
      </div>

      <div className="space-y-8">
        <div className="bg-white rounded-xl shadow-sm p-6">
          <TrendChart data={feedback} />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 bg-white rounded-xl shadow-sm p-6">
            <FeedbackTable data={feedback} />
            {loading && cursor && (
              <div className="text-center mt-4">
                <Loader2 className="w-6 h-6 animate-spin mx-auto" />
                <p className="text-sm text-gray-500 mt-2">Loading more...</p>
              </div>
            )}
            {!loading && hasMore && (
              <div className="text-center mt-4">
                <button
                  onClick={loadMore}
                  className="text-blue-600 hover:text-blue-800 text-sm font-medium"
                >
                  Load More
                </button>
              </div>
            )}
          </div>
          <div className="bg-white rounded-xl shadow-sm p-6">
            <ActivityFeed activities={activities} />
          </div>
        </div>
      </div>
    </div>
  );
}